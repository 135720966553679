<template>
  <component :is="productsData === undefined ? 'div' : 'div'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="productsData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No product product found with this product product id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-products-list'}"
        >
          Product List
        </b-link>
        for other products.
      </div>
    </b-alert>
    <template #title>
      <feather-icon
        icon="PackageIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="d-none d-sm-inline">Informatie</span>
    </template>

    <template v-if="productsData">
      <!-- company Info: Input Fields -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="mb-2"
          @submit.prevent="handleSubmit(updateProduct)"
        >
          <b-card>
            <!-- Header: company Info -->
            <div class="d-flex mb-1">
              <feather-icon
                icon="PackageIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                Informatie
              </h4>
            </div>
            <b-row>

              <!-- Field: ID -->
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="ID"
                  label-for="id"
                >
                  <b-form-input
                    id="id"
                    v-model="productsData.id"
                    readonly="readonly"
                  />
                </b-form-group>
              </b-col>

              <!-- CreatedDate -->
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Aangemaakt op"
                  label-for="createTimestamp"
                >
                  <b-form-input
                    id="createTimestamp"
                    v-model="productsData.createTimestamp"
                    readonly="readonly"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Name -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="name"
                  rules="required"
                >
                  <b-form-group
                    label="Naam"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="productsData.name"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Status -->
              <b-col
                cols="12"
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  name="user-status"
                  rules="required"
                >
                  <b-form-group
                    label="Status"
                    label-for="user-status"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="productsData.active"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="user-status"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="description"
                  rules="required"
                >
                  <b-form-group
                    label="Beschrijving"
                    label-for="description"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="productsData.description"
                      rows="3"
                      no-resize
                      maxlength="100"
                      trim
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>
          <b-card>
            <!-- Header: company Info -->
            <div class="d-flex mb-1">
              <feather-icon
                icon="BookIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                Subgrootboeken
              </h4>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="externalIdentifier1"
                  rules="required"
                >
                  <b-form-group
                    label="Bank - Subgrootboek"
                    label-for="externalIdentifier1"
                  >
                    <b-form-input
                      id="externalIdentifier1"
                      v-model="productsData.externalIdentifier1"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="externalIdentifier2"
                  rules="required"
                >
                  <b-form-group
                    label="Ministerie - Subgrootboek"
                    label-for="externalIdentifier2"
                  >
                    <b-form-input
                      id="externalIdentifier2"
                      v-model="productsData.externalIdentifier2"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="externalIdentifier3"
                  rules="required"
                >
                  <b-form-group
                    label="Parastataal - Subgrootboek"
                    label-for="externalIdentifier3"
                  >
                    <b-form-input
                      id="externalIdentifier3"
                      v-model="productsData.externalIdentifier3"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="externalIdentifier5"
                  rules="required"
                >
                  <b-form-group
                    label="Innende agenten Nickerie - Subgrootboek"
                    label-for="externalIdentifier5"
                  >
                    <b-form-input
                      id="externalIdentifier5"
                      v-model="productsData.externalIdentifier5"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="externalIdentifier6"
                  rules="required"
                >
                  <b-form-group
                    label="Overige innende agenten - Subgrootboek"
                    label-for="externalIdentifier6"
                  >
                    <b-form-input
                      id="externalIdentifier6"
                      v-model="productsData.externalIdentifier6"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="externalIdentifier7"
                  rules="required"
                >
                  <b-form-group
                    label="Agent inwording - Subgrootboek"
                    label-for="externalIdentifier7"
                  >
                    <b-form-input
                      id="externalIdentifier7"
                      v-model="productsData.externalIdentifier7"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="externalIdentifier4"
                  rules="required"
                >
                  <b-form-group
                    label="Overige - Subgrootboek"
                    label-for="externalIdentifier4"
                  >
                    <b-form-input
                      id="externalIdentifier"
                      v-model="productsData.externalIdentifier4"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>
          <b-card>
            <div class="d-flex mb-1">
              <feather-icon
                icon="SettingsIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                Configuratie
              </h4>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="polisNumberPrefix"
                  rules="required"
                >
                  <b-form-group
                    label="Polisnummer Prefix"
                    label-for="polisNumberPrefix"
                  >
                    <b-form-input
                      id="polisNumberPrefix"
                      v-model="productsData.polisNumberPrefix "
                      :state="getValidationState(validationContext)"
                      type="text"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="type"
                  rules="required"
                >
                  <b-form-group
                    label="Product Type"
                    label-for="type"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="type"
                      v-model="productsData.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="productTypeOptions"
                      :reduce="val => val.value"
                      label="label"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="categoryID"
                  rules="required"
                >
                  <b-form-group
                    label="Product Categorie"
                    label-for="categoryID"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="categoryID"
                      v-model="productsData.categoryID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="organisationData"
                      :reduce="val => val.id"
                      label="name"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="calculationMethodID"
                  rules="required"
                >
                  <b-form-group
                    label="Rekenmethode"
                    label-for="calculationMethodID"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="calculationMethodID"
                      v-model="productsData.calculationMethodID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="calculationMethodOptions"
                      :reduce="val => val.value"
                      label="label"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="allowForeignCurrency"
                  rules="required"
                >
                  <b-form-group
                    label="Vreemde valuta toestaan"
                    label-for="allowForeignCurrency"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="allowForeignCurrency"
                      v-model="productsData.allowForeignCurrency"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="yesOrNoOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="hasLenderOption"
                  rules="required"
                >
                  <b-form-group
                    label="Krediet of hypotheek vraag tonen?"
                    label-for="hasLenderOption"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="hasLenderOption"
                      v-model="productsData.hasLenderOption"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="yesOrNoOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="hasPartialPayment"
                  rules="required"
                >
                  <b-form-group
                    label="Heeft gedeeltelijke betaling"
                    label-for="hasPartialPayment"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="hasPartialPayment"
                      v-model="productsData.hasPartialPayment"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="yesOrNoOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="prolongationType"
                  rules="required"
                >
                  <b-form-group
                    label="Prolongatie type"
                    label-for="prolongationType"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="prolongationType"
                      v-model="productsData.prolongationType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="prolongationTypeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="premiumDocumentID"
                  rules="required"
                >
                  <b-form-group
                    label="Polisdocument"
                    label-for="premiumDocumentID"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="premiumDocumentID"
                      v-model="productsData.premiumDocumentID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="documentPolisOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="offerDocumentID"
                  rules="required"
                >
                  <b-form-group
                    label="Offertedocument"
                    label-for="offerDocumentID"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="offerDocumentID"
                      v-model="productsData.offerDocumentID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="documentOfferOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="minContractDuration"
                  rules="required|integer"
                >
                  <b-form-group
                    label="Minimale looptijd contract"
                    label-for="minContractDuration"
                  >
                    <b-form-input
                      id="minContractDuration"
                      v-model="productsData.minContractDuration"
                      typr="number"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="maxContractDuration"
                  rules="required|integer"
                >
                  <b-form-group
                    label="Maximale looptijd contract"
                    label-for="maxContractDuration"
                  >
                    <b-form-input
                      id="maxContractDuration"
                      v-model="productsData.maxContractDuration"
                      typr="number"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="minInsuredRelations"
                  rules="required|integer"
                >
                  <b-form-group
                    label="Minimaal aantal verzekerde"
                    label-for="minInsuredRelations"
                  >
                    <b-form-input
                      id="minInsuredRelations"
                      v-model="productsData.minInsuredRelations"
                      :state="getValidationState(validationContext)"
                      type="number"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="maxInsuredRelations"
                  rules="required|integer"
                >
                  <b-form-group
                    label="Maximaal aantal verzekerde"
                    label-for="maxInsuredRelations"
                  >
                    <b-form-input
                      id="maxInsuredRelations"
                      v-model="productsData.maxInsuredRelations"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Is looptijd afhankelijk van leeftijd?"
                  label-for="hasDurationLimitByAge"
                >
                  <v-select
                    id="hasDurationLimitByAge"
                    v-model="productsData.hasDurationLimitByAge"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="hasDurationLimitByAgeOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="minBenificiaries"
                  rules="required|integer"
                >
                  <b-form-group
                    label="Minimaal aantal begunstigden"
                    label-for="minBenificiaries"
                  >
                    <b-form-input
                      id="minBenificiaries"
                      v-model="productsData.minBenificiaries"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="maxBenificiaries"
                  rules="required|integer"
                >
                  <b-form-group
                    label="Maximaal aantal begunstigden"
                    label-for="maxBenificiaries"
                  >
                    <b-form-input
                      id="maxBenificiaries"
                      v-model="productsData.maxBenificiaries"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="maxContractAge"
                  rules="required|integer"
                >
                  <b-form-group
                    label="Maximale leeftijd"
                    label-for="maxContractAge"
                  >
                    <b-form-input
                      id="maxContractAge"
                      v-model="productsData.maxContractAge"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Heeft een formulier?"
                  label-for="hasForm"
                >
                  <v-select
                    id="hasForm"
                    v-model="productsData.hasForm"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="yesOrNoOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="productsData.hasForm === 1"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Formulier"
                  label-for="formID"
                >
                  <v-select
                    id="formID"
                    v-model="productsData.formID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="formsOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="productsData.hasForm === 1"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Formulierberekeningsmethode"
                  label-for="formCalculationMethodID"
                >
                  <v-select
                    id="formCalculationMethodID"
                    v-model="productsData.formCalculationMethodID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="formCalculationOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <b-card>
            <div class="d-flex mb-1">
              <feather-icon
                icon="CreditCardIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                Financiële configuratie
              </h4>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="policyCostSRD"
                  rules="required|decimal"
                >
                  <b-form-group
                    label="Poliskosten SRD"
                    label-for="policyCostSRD"
                  >
                    <b-form-input
                      id="policyCostSRD"
                      v-model="productsData.policyCostSRD"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="policyCostUSD"
                  rules="required|decimal"
                >
                  <b-form-group
                    label="Poliskosten USD"
                    label-for="policyCostUSD"
                  >
                    <b-form-input
                      id="policyCostUSD"
                      v-model="productsData.policyCostUSD"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="policyCostEUR"
                  rules="required|decimal"
                >
                  <b-form-group
                    label="Poliskosten EUR"
                    label-for="policyCostEUR"
                  >
                    <b-form-input
                      id="policyCostEUR"
                      v-model="productsData.policyCostEUR"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="insuredAmountAutoAcceptationLimitSRD"
                  rules="required|decimal"
                >
                  <b-form-group
                    label="Automatische acceptatie limiet SRD"
                    label-for="insuredAmountAutoAcceptationLimitSRD"
                  >
                    <b-form-input
                      id="insuredAmountAutoAcceptationLimitSRD"
                      v-model="productsData.insuredAmountAutoAcceptationLimitSRD"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="insuredAmountAutoAcceptationLimitUSD"
                  rules="required|decimal"
                >
                  <b-form-group
                    label="Automatische acceptatie limiet USD"
                    label-for="insuredAmountAutoAcceptationLimitUSD"
                  >
                    <b-form-input
                      id="insuredAmountAutoAcceptationLimitUSD"
                      v-model="productsData.insuredAmountAutoAcceptationLimitUSD"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="insuredAmountAutoAcceptationLimitEUR"
                  rules="required|decimal"
                >
                  <b-form-group
                    label="Automatische acceptatie limiet EUR"
                    label-for="insuredAmountAutoAcceptationLimitEUR"
                  >
                    <b-form-input
                      id="insuredAmountAutoAcceptationLimitEUR"
                      v-model="productsData.insuredAmountAutoAcceptationLimitEUR"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="minInsuredAmountSRD"
                  rules="required|decimal"
                >
                  <b-form-group
                    label="Minimaal verzekerd bedrag SRD"
                    label-for="minInsuredAmountSRD"
                  >
                    <b-form-input
                      id="minInsuredAmountSRD"
                      v-model="productsData.minInsuredAmountSRD"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="minInsuredAmountUSD"
                  rules="required|decimal"
                >
                  <b-form-group
                    label="Minimaal verzekerd bedrag USD"
                    label-for="minInsuredAmountUSD"
                  >
                    <b-form-input
                      id="minInsuredAmountUSD"
                      v-model="productsData.minInsuredAmountUSD"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="minInsuredAmountEUR"
                  rules="required|decimal"
                >
                  <b-form-group
                    label="Minimaal verzekerd bedrag EUR"
                    label-for="minInsuredAmountEUR"
                  >
                    <b-form-input
                      id="minInsuredAmountEUR"
                      v-model="productsData.minInsuredAmountEUR"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="maxInsuredAmountSRD"
                  rules="required|decimal"
                >
                  <b-form-group
                    label="Maximaal verzekerd bedrag SRD"
                    label-for="maxInsuredAmountSRD"
                  >
                    <b-form-input
                      id="maxInsuredAmountSRD"
                      v-model="productsData.maxInsuredAmountSRD"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="maxInsuredAmountUSD"
                  rules="required|decimal"
                >
                  <b-form-group
                    label="Maximaal verzekerd bedrag USD"
                    label-for="maxInsuredAmountUSD"
                  >
                    <b-form-input
                      id="maxInsuredAmountUSD"
                      v-model="productsData.maxInsuredAmountUSD"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="maxInsuredAmountEUR"
                  rules="required|decimal"
                >
                  <b-form-group
                    label="Maximaal verzekerd bedrag EUR"
                    label-for="minInsuredAmountEUR"
                  >
                    <b-form-input
                      id="maxInsuredAmountEUR"
                      v-model="productsData.maxInsuredAmountEUR"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="gvsBranche"
                  rules="required"
                >
                  <b-form-group
                    label="GVS Branche"
                    label-for="gvsBranche "
                  >
                    <b-form-input
                      id="gvsBranche "
                      v-model="productsData.gvsBranche "
                      :state="getValidationState(validationContext)"
                      type="text"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>
          <b-row>
            <b-col
              cols="12"
            >
              <b-card>
                <b-row>
                  <b-col
                    cols="6 text-left"
                  >
                    <b-button
                      variant="secondary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      type="button"
                      :to="{ name: 'apps-products-list' }"
                    >
                      <span>Terug</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="6 text-right"
                  >
                    <b-button
                      v-if="$can('update', 'product')"
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      type="submit"
                    >
                      <span>Opslaan</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>

  </component>
</template>

<script>
import {
  BCard, BAlert, BLink, BButton, BFormGroup, BFormInput, BForm, BFormTextarea, BRow, BCol, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { avatarText } from '@core/utils/filter'
import { formatDateTime } from '@core/utils/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import productsStoreModule from '@/views/pages/products/productsStoreModule'
import useProductsList from '@/views/pages/products/useProductsList'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  alphaNum,
  integer,
  required,
  decimal,
} from '@core/utils/validations/validations'

export default {
  components: {
    BButton,
    BCard,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      decimal,
      productsData: {
        type: null,
        name: '',
        description: '',
        createTimestamp: '',
        active: null,
        categoryID: null,
        policyCostSRD: null, // decimal
        policyCostUSD: null, // decimal
        policyCostEUR: null, // decimal
        insuredAmountAutoAcceptationLimitSRD: null,
        insuredAmountAutoAcceptationLimitEUR: null,
        insuredAmountAutoAcceptationLimitUSD: null,
        minInsuredAmountSRD: null,
        maxInsuredAmountSRD: null,
        minInsuredAmountEUR: null,
        maxInsuredAmountEUR: null,
        minInsuredAmountUSD: null,
        maxInsuredAmountUSD: null,
        minInsuredRelations: null,
        maxInsuredRelations: null,
        minBenificiaries: null,
        maxBenificiaries: null,
        allowForeignCurrency: null,
        hasLenderOption: null,
        hasPartialPayment: null,
        prolongationType: null,
        premiumDocumentID: null,
        offerDocumentID: null,
        maxContractDuration: null,
        minContractDuration: null,
        hasForm: 0,
        hasDurationLimitByAge: 0,
        maxContractAge: null,
        externalIdentifier1: '',
        externalIdentifier2: '',
        externalIdentifier3: '',
        externalIdentifier4: '',
        externalIdentifier5: '',
        externalIdentifier6: '',
        externalIdentifier7: '',
        gvsBranche: '',
        polisNumberPrefix: '',
      },
      organisationData: [],
    }
  },
  beforeMount() {
    store.dispatch('apps-products-edit/fetchProduct', { id: router.currentRoute.params.id })
      .then(response => {
        response.data.createTimestamp = formatDateTime(response.data.createTimestamp)
        this.productsData = response.data
      })
      .catch(() => {
        this.productsData = undefined
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de productinformatie',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    store.dispatch('apps-products-edit/fetchProductCategories', { sort: 'id' })
      .then(response => {
        this.organisationData = response.data.items
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de productcategorieen',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    updateProduct() {
      store.dispatch('apps-products-edit/updateProducts', this.productsData)
        .then(() => {
          router.push({ name: 'apps-products-list' })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het updaten van het product',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'apps-products-edit'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, productsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const {
      statusOptions,
      calculationMethodOptions,
      yesOrNoOptions,
      prolongationTypeOptions,
      documentPolisOptions,
      documentOfferOptions,
      formsOptions,
      formCalculationOptions,
      hasDurationLimitByAgeOptions,
      productTypeOptions,
    } = useProductsList()

    return {
      statusOptions,
      calculationMethodOptions,
      yesOrNoOptions,
      prolongationTypeOptions,
      documentPolisOptions,
      documentOfferOptions,
      formsOptions,
      formCalculationOptions,
      avatarText,
      refFormObserver,
      getValidationState,
      hasDurationLimitByAgeOptions,
      productTypeOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
